
import { Component, Vue } from 'vue-property-decorator'
import { CampusInfo } from '@/types/school.d'

@Component({
  name: 'campusList'
})
export default class extends Vue {
  private loading = false
  private tableData: CampusInfo[] = []
  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<CampusInfo> }>(this.$apis.school.selectCampusInfoByPage, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({
      name: 'campusAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'campusEdit',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.school.deleteCampusInfo, {
        infoId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.page = 1
        this.getData()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'campusDetail',
      params: { id }
    })
  }
}
